






















































































































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss",
      url: "url"
    })
  },
  methods: {
    title(title: string): string {
      if (this.isMobile) {
        return title;
      } else {
        return title.replace("<br />", "");
      }
    }
  }
});
